import React, { useState } from 'react';
import paperRocket from '@/client/assets/images/mask_group.png'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@rocket-resume/ui'
import bulb from '@/client/assets/images/bulb.svg'
import questionmark from '@/client/assets/images/questionmark.png'


const Faqs = () => {
  const [activeQuestionId, setActiveQuestionId] = useState<string | null>(null);

  const questionsData = [
    {
      id: "1",
      question: "What kind of CVs can I create with your builder?",
      answer: `Our builder is designed for a wide range of professions and experience levels.
        You can create CVs for entry-level positions, experienced professionals, 
        and even career changers.`
    },
    {
      id: "2",
      question: "Is it free to use your platform?",
      answer: "Lorem ipsum dorime."
    },
    {
      id: "3",
      question: "How long does it take to build a CV with your platform?",
      answer: `Dori me, interimo adapare dori me
        Ameno, ameno, latire, latire mo`
    },
    {
      id: "4",
      question: "Do you offer any help with writing my CV content?",
      answer: `Dori me, ameno, oma nare imperavi ameno
        Dimere, dimere, mantiro, mantire mo Ameno`
    },
    {
      id: "5",
      question: "What file formats can I download my CV in?",
      answer: `Dori me, ameno, oma nare imperavi ameno
        Dimere, dimere, mantiro, mantire mo Ameno`
    },
    {
      id: "6",
      question: "Is your platform compatible with Applicant Tracking Systems (ATS)?",
      answer: `Dori me, ameno, oma nare imperavi ameno
        Dimere, dimere, mantiro, mantire mo Ameno`
    },
    {
      id: "7",
      question: "How do I share my CV with potential employers?",
      answer: `Dori me, ameno, oma nare imperavi ameno
        Dimere, dimere, mantiro, mantire mo Ameno`
    },
    {
      id: "8",
      question: "Is my information secure on your platform?",
      answer: `Dori me, ameno, oma nare imperavi ameno
        Dimere, dimere, mantiro, mantire mo Ameno`
    },
  ];

  const toggleQuestion = (id: string) => {
    setActiveQuestionId(activeQuestionId === id ? null : id);
  };

  const Question = ({ id, question, answer }: any) =>{ 
    const isOpen = activeQuestionId === id;
    
    return(
      <AccordionItem value={id}>
      <AccordionTrigger className="text-left leading-relaxed text-[14px] lg:text-lg text-[#FFFFFF]" onClick={() => toggleQuestion(id)} doNotShowDropIcon>
        <div className='flex gap-2'>
          <img src={isOpen ? bulb : questionmark} alt="questionmark" className='max-w-[40%] lg:max-w-[100%]' />
          <p>{question}</p>
        </div>
      </AccordionTrigger>
      <AccordionContent className="prose max-w-none dark:prose-invert text-[13px] lg:text-base text-[#edebeb]">
        <p>{answer}</p>
      </AccordionContent>
    </AccordionItem>
    )
  };

  return (
    <section id="faqs" className="relative bg-[#121211]">
      <div className='mx-auto w-full pl-[1rem] xs:pl-[2rem] lg:pl-[4rem] pr-[2rem] lg:pr-[6rem] xl:pl-[9rem] pt-[4%] lg:pt-[5%] flex justify-between flex-col xs:flex-row'>
        <div className="w-[100%] xs:w-[60%] lg:w-1/2">
          <h1 className='text-[1.5rem] xs:text-[2rem] lg:text-5xl font-semibold text-[#FFFFFF]'>{`Faqs`}</h1>
          <div className="col-span-2">
            <Accordion collapsible type="single">
              {questionsData.map(({ id, question, answer }) => (
                <Question key={id} id={id} question={question} answer={answer} />
              ))}
            </Accordion>
          </div>
        </div>
        <div className="w-[100%] xs:w-[40%] lg:w-1/2 flex justify-center items-center"><img src={paperRocket} alt="paperRocket" /></div>
        
      </div>
    </section>
  )
}

export default Faqs